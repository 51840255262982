/* Importing node modules SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "~simple-datatables/dist/style.css";
@import "~leaflet/dist/leaflet.css";
@import "~chartist/dist/scss/chartist.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-archwizard/archwizard.css';
@import '~animate.css/animate.min.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~angular-calendar/scss/angular-calendar.scss";
@import "~flatpickr/dist/flatpickr.css";
@import '~highlight.js/scss/solarized-dark.scss';

//custom scss
@import "scss/style.scss";
// @import "css/skins.scss";
// @import "css/dark-style.scss";
// @import "css/colors/default.scss";
@import "assets/css/icon-list.css";
// @import "css/colors/color.scss";

@import "css/sidemenu/sidemenu.scss";

.pac-container {
    z-index: 2000;
    display: inline-block;
}
.md-drppicker{
    display: flex;
    top: 55px !important;
    
    &.double{left: unset !important; right: 0 !important;}
}

.btn i.typcn.typcn-eye {
    line-height: 12px;
    font-size: 1.1em;
}

.main-content{
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 90vh;
        background-image: url(../assets/img/pattern.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        right: 0;bottom: 0;
        opacity: 0.2;
        pointer-events: none;
        mix-blend-mode: darken;
    }
}

app-sidemenu{
    .ps__rail-x{display: none !important;}
    .ps.ps--active-y:hover .ps__rail-y{right: 0 !important;}
}


tr.mat-header-row{height: fit-content !important;}
th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{padding-left: 6px !important;}
th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type{padding-right: 6px !important;}
.mat-sort-header-arrow{top: -2px; color: var(--primary);}

tr.mat-row, tr.mat-footer-row{height: auto !important;}

.mat-paginator-range-actions button{
    border-radius: 8px;
    border: 1px solid #0002;

    &:not(:last-child){margin-right: 8px;}

    .mat-button-wrapper{
        position: relative;top: -2px;
    }
    

    &:not(.mat-button-disabled){color: var(--primary);}
}

._pill{
    width: fit-content;
    margin: auto;
    display: flex;
    padding: 3px 10px;
    border-radius: 10px;
    // background-color: hsla(224, 33%, 23%, 0.102);

    span{
        height: 21px;
        padding: 2px;
        font-size: .9em;
        font-weight: 700;
        white-space: nowrap;

        &:first-child{width: 22px;}
    }
    
}

textarea{resize: none;}
.ng-select{height: auto;}


.popover{
    z-index: 1051 !important;

    &.has-header{.arrow::after{border-bottom-color: #2a9742 ;}}

    .popover-header{
        text-transform: none !important;
        font-size: 14px;
    }
}

//CLASE PARA COLORES DE BADGE DEPENDIENDO DEL STATUS.
.badge{
    &.badge-st-created{background-color: #38c063; color: #fff};
    &.badge-st-inprogress{background-color: #d59928; color: #fff};
    &.badge-st-closed{background-color: #72716e; color: #fff};
    &.badge-st-deleted{background-color: #e90000; color: #fff};

// CLASES PARA STATUS EN MODULO DE EVALUACIONES
    &.badge-st-rejected-obj{background-color: #e90000; color: #fff};
    &.badge-st-created-obj{background-color: #d59928; color: #fff};
    &.badge-st-authorized-obj{background-color: #38c063; color: #fff};

}