/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.swal2-container{

  padding: 0 0 0 !important;
//   bottom: 0 !important;
//   left: 0 !important;
//   pointer-events: none;
//   box-shadow: 0 2px 14px #0006;

  .swal2-popup{
    pointer-events: all;
    
    &:not(.swal2-toast){
      background-image: url(./assets/img/modal-texture.png) !important;
      background-size: cover;
      border-radius: 11px !important;
      width: 420px !important;

      .swal2-content{
        font-size: 1rem !important;
      }
    }

    &.swal2-toast{
    margin: 8px auto !important;
    box-shadow: 0 1px 4px #0006;
    color: white;
    border-radius: 8px !important;
    padding: 4px 10px !important;
  
    background-image: url(./assets/img/trianglePattern.png) !important;
    background-size: cover !important;
    // font-size: inherit !important;
  }

    &.swal-3-opt{
      width: 500px !important;

      .swal2-actions .swal2-styled{min-width: 25%;}
      .swal2-cancel.swal2-styled{
        background-color: var(--dark) !important;
      }
      .swal2-deny.swal2-styled{
        background-color: var(--danger) !important;
      }
    }
  }
  .swal2-actions{
    justify-content: space-between;
    padding: 0 !important;
    flex-wrap: nowrap !important;

    .swal2-styled{border-radius: 11px !important; min-width: 33%; margin: 5px 0;}
    .swal2-confirm.swal2-styled{
      background-color: var(--success) !important;
      order: 2;
    }
    .swal2-cancel.swal2-styled{
      background-color: var(--danger) !important;
      order: 1;
    }
    .swal2-deny.swal2-styled{
      background-color: var(--danger) !important;
      //--warning
    }

    .swal2-deny + .swal2-cancel.swal2-styled{order: -1;}
  }
}
.mat-snack-bar-container{
    margin: 8px auto !important;
    box-shadow: 0 1px 4px #0006;
    background: var(--primary);
    color: white;
    border-radius: 8px !important;

    background-image: url(./assets/img/trianglePattern.png);
    background-size: cover;
    
    .mat-simple-snackbar-action{
        color: white;
        background: #0002;
        border-radius: 5px;
    }
}

.table.mat-table{
  table-layout: fixed;
}